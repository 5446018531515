/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { makeStyles, Theme } from '@material-ui/core/styles';
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import BookIcon from '@material-ui/icons/Book';
import InfoIcon from '@material-ui/icons/Info';
import MenuIcon from '@material-ui/icons/Menu';
import GavelIcon from '@material-ui/icons/Gavel';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import YouTubeIcon from '@material-ui/icons/YouTube';
import TwitterIcon from '@material-ui/icons/Twitter';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import FacebookIcon from '@material-ui/icons/Facebook';
import SecurityIcon from '@material-ui/icons/Security';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import DashboardIcon from '@material-ui/icons/Dashboard';
import InstagramIcon from '@material-ui/icons/Instagram';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import Alert from '@material-ui/lab/Alert';
import { Helmet } from 'react-helmet';
import { Link as LinkRouter, useHistory, useLocation } from 'react-router-dom';
import {
  clearStorage,
  getImageProfile,
  getUserData as getUserDataRequest,
  loginRedirectPrivider,
  logout,
  getAdditionalDataUserSso,
} from '../../services/auth';
import { cpfMask } from '../../utils/cpfFormat';
import AlertDataComplements from './components/AlertDataComplements';
// eslint-disable-next-line import/extensions
import ColorContext from '../../Context/colorContext';
import RefContext from '../../Context/refContext';
import { UserData } from '../../services/user';
import { getInitialsFromName } from '../../utils/nameFormat';
import AlertAcceptCookies from '../AlertAcceptCookies';
import Menu from '../Menu';
import ModalAlertsDialog from '../ModalAlertsDialog';
import ModalNotAuth from '../ModalNotAuth';
import QuickSchedule from '../QuickSchedule';
import TermoDialog from '../TermoDialog';
import getCookie from '../../utils/getCookies';

export interface DadosComplementares {
  complementar: {
    bairro: null | string;
    cep: null | string;
    cidade: null | string;
    complemento: null | string;
    dt_nascimento_conjuge: string;
    endereco: null | string;
    escolaridade: string;
    estado: string;
    estado_civil: string;
    filho: any[];
    id: number;
    nacionalidade: string;
    naturalidade: string;
    nome_conjuge: string;
    numero: null | string;
    pcd: boolean;
    raca: string;
    tipo_logradouro: null | string;
    tipo_pcd: null | string;
  };
  contato: {
    celular: string;
    email: string;
    email_verificado: boolean;
    facebook: null | string;
    id: number;
    instagram: null | string;
    telefone: null | string;
    twitter: null | string;
    whatsapp: null | string;
  };
  created_at: string;
  data_nascimento: string;
  nome_mae: string;
  nome_pai: string;
  nome_social: string | null;
  sexo: string;
  updated_at: string;
}

interface Props {
  setPerfilUser: React.Dispatch<any>;
}

export default function Header({ setPerfilUser }: Props): JSX.Element {
  const { colors } = useContext(ColorContext);
  const matches = useMediaQuery('(min-width:1250px)');
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      height: 100,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0px 10px',
      background: colors.accentColorBackground,
      color: colors.accentColor,
      [theme.breakpoints.down(1250)]: {
        height: 'auto',
      },
    },
    text: {
      textTransform: 'capitalize',
      fontFamily: 'Roboto',
      margin: '0px 15px',
      color: colors.textBody,
      cursor: 'pointer',
    },
    textButton: {
      fontWeight: 500,
      fontStyle: 'normal',
      marginLeft: 3,
      fontSize: 16,
      color: colors.textAccentColor,
      textTransform: 'uppercase',
    },
    textName: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 'bold',
      textTransform: 'capitalize',
      color: colors.textAccentColor,
    },
    textNamePop: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 500,
      textTransform: 'capitalize',
      color: colors.accentColor,
    },
    avatar: {
      height: 40,
      width: 40,
      backgroundColor: '#737B7D',
      border: `1px solid ${colors.accentColor}`,
    },
    menuItem: {
      padding: '18px 37px 18px 20px',
      display: 'flex',
      alignItems: 'center',
      color: '#737B7D',
      minWidth: 236,
      backgroundColor: colors.colorBackgroundSecundary,
      '&:hover': {
        background: colors.colorBackground,
      },
      [theme.breakpoints.down('xs')]: {
        padding: '10px 16px',
      },
    },
    textMenuItem: {
      fontSize: theme.typography.pxToRem(16),
      marginLeft: 15,
      fontFamily: 'Roboto',
      fontWeight: 500,
      color: colors.textBody,
    },
    textCpf: {
      fontSize: theme.typography.pxToRem(12),
      fontWeight: 400,
      textAlign: 'start',
      fontFamily: 'Roboto',
      color: colors.textAccentColor,
    },
    textCpfPop: {
      fontSize: theme.typography.pxToRem(12),
      fontWeight: 400,
      textAlign: 'start',
      fontFamily: 'Roboto',
      color: colors.accentColor,
    },
    buttonAccessibility: {
      display: 'inline-block',
      padding: 6,
      minWidth: 0,
      minHeight: 0,
      fontSize: 14,
      justifyContent: 'center',
      alignItems: 'center',
      color: colors.accentColor,
    },
    boxAccessibility: {
      display: 'flex',
      alignItems: 'center',
    },
    boxSubMenu: {
      display: 'flex',
      alignItems: 'center',
      gap: 40,
    },
    boxSub: {
      width: '1050px',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row-reverse',
      alignItems: 'center',
    },
    boxSocialMedia: {
      display: 'flex',
      alignItems: 'center',
      gap: 9,
    },
    boxSocialMediaItems: {
      display: 'flex',
      alignItems: 'center',
      gap: 8,
    },
    titleBoxSocialMedia: {
      fontSize: theme.typography.pxToRem(14),
      color: matches ? '#053396' : colors.textAccentColor,
      fontWeight: 400,
    },
    linksBoxSubMenu: {
      fontSize: theme.typography.pxToRem(13),
      fontFamily: 'Roboto',
      fontWeight: 400,
      color: matches ? '#053396' : '#fff',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      gap: 3,
    },
    textLocale: {
      display: 'flex',
      alignItems: 'center',
      padding: '3px 15px',
      fontSize: 12,
      fontFamily: 'Roboto',
      fontWeight: 500,
      color: '#3E3E3E',
      borderLeft: '1px solid #DBDFE2',
      borderRight: '1px solid #DBDFE2',
    },
    textFontContrast: {
      fontSize: 14,
      fontWeight: 500,
      color: colors.textBody,
    },
    boxInfo: {
      fontFamily: 'Roboto',
      fontWeight: 400,
      color: colors.accentColor,
    },
    btnSocial: {
      backgroundColor: matches ? '#053396' : colors.colorBackgroundSecundary,
      color: '#fff',
      padding: 0,
      width: 16,
      height: 16,
      borderRadius: '50%',
      '&:hover': {
        backgroundColor: '#053396',
      },
    },
    btnFacebook: {
      color: colors.textAccentColor,
      padding: 0,
      width: 16,
      height: 16,
    },
    boxIcon: {
      width: 32,
      height: 32,
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: colors.accentColor,
      '&:hover': {
        backgroundColor: colors.accentColor,
        opacity: 0.8,
      },
    },
    iconSocial: {
      width: 12,
      height: 12,
    },
    img: {
      width: '100%',
      maxWidth: 220,
    },

    backdropMenu: {
      width: '100vw',
      height: '100vh',
      background: colors.accentColorBackground,
    },
    menuHeader: {
      padding: '24px 20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    menuHeaderTitle: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 700,
      textTransform: 'uppercase',
      color: colors.textAccentColor,
    },
    itemMenu: {
      padding: '15px 20px',
    },
    menuPrimary: {
      width: '100%',
      height: 32,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    contentMenuPrimary: {
      maxWidth: 1320,
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      gap: 32,
    },
    iconMenuWorkspace: {
      width: 24,
      height: 24,
      color: colors.accentColor,
    },
    userPanel: {
      padding: '11px 22px',
      textTransform: 'uppercase',
      fontSize: theme.typography.pxToRem(15),
      color: colors.accentColor,
      backgroundColor: colors.colorBackground,
      borderRadius: 24,
      fontWeight: 500,

      '&:hover': {
        backgroundColor: colors.colorBackground,
      },
    },
  }));

  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const matchesMobile = useMediaQuery('(min-width:660px)');
  const token: string | null = getCookie('gov_access_token_sso');
  const tokenSiseci: string | null = getCookie('gov_access_token_sso');

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [anchorElUser, setAnchorElUser] = React.useState<HTMLButtonElement | null>(null);
  const [anchorElUserPanel, setAnchorElUserPanel] = React.useState<HTMLButtonElement | null>(null);
  const [userData, setUserData] = useState<UserData | null>();
  const [message, setMessage] = useState<string>('');
  const [avatarUser, setAvatarUser] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isOpenOptions, setIsOpenOptions] = useState(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isAuth, setIsAuth] = useState<boolean>(true);
  const [openModalComplements, setOpenModalComplements] = useState<boolean>(false);

  const linkFace = process.env.REACT_APP_LINK_FACEBOOK ?? '';
  const linkInsta = process.env.REACT_APP_LINK_INSTAGRAM ?? '';
  const linkYoutube = process.env.REACT_APP_LINK_YOUTUBE ?? '';
  const linkTwitter = process.env.REACT_APP_LINK_TWITTER ?? '';

  const goToPage = (link: string) => {
    window.open(link);
  };

  const { refInicio } = useContext(RefContext);

  useEffect(() => {
    const { state } = location;
    if (state as any) {
      setMessage((state as any).message);
    }
  }, [location]);

  const getUserData = async (tokenSso: string, tokenSiseciReceive: string) => {
    try {
      setLoading(true);

      const data = await getUserDataRequest(tokenSso, tokenSiseciReceive);
      setUserData(data);
      setPerfilUser(data);
      document.cookie = `gov_user_data=${encodeURIComponent(
        JSON.stringify(data),
      )};path=/`;
      // setLoading(false);
    } catch (err) {
      clearStorage();
      if (err.response?.data) {
        setMessage(err.response.data.detail);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyDataComplements = async () => {
    if (token && userData) {
      try {
        const { data } = await getAdditionalDataUserSso(token, userData.cpf);
        if (data.complementar) {
          sessionStorage.setItem('@data-complements', 'true');
        } else {
          setOpenModalComplements(true);
          sessionStorage.setItem('@data-complements', 'false');
        }
      } catch (error) {
        if (error?.response?.status === 404) {
          sessionStorage.setItem('@data-complements', 'false');
          setOpenModalComplements(true);
        }
      }
    }
  };

  const hasComplements = sessionStorage.getItem('@data-complements');

  useEffect(() => {
    if (token && tokenSiseci) {
      getUserData(token, tokenSiseci);
      setIsAuth(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (token && tokenSiseci) {
      getUserData(token, tokenSiseci);
      setIsAuth(true);
    }
  }, [token, tokenSiseci]);

  useEffect(() => {
    if (token && userData && !hasComplements) {
      handleVerifyDataComplements();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, userData, hasComplements]);

  useEffect(() => {
    if (hasComplements === 'false') {
      setOpenModalComplements(true);
    }
  }, [hasComplements]);

  const handleClick = (
    event: React.MouseEvent<any>,
    type?: 'user' | 'access' | 'user-panel',
  ) => {
    switch (type) {
      case 'user':
        setAnchorElUser(event.currentTarget);
        break;
      case 'user-panel':
        setAnchorElUserPanel(event.currentTarget);
        break;
      default:
        setAnchorEl(event.currentTarget);
        break;
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorElUser(null);
    setAnchorElUserPanel(null);
    setIsOpenMenu(false);
    setIsOpenOptions(false);
  };

  const logoutUser = () => {
    logout();
    setUserData(undefined);
    setAnchorElUser(null);
    setAnchorElUserPanel(null);
  };

  const handleOpenMenu = () => {
    setIsOpenMenu(!isOpenMenu);
  };

  const handleOpenOptions = () => {
    setIsOpenOptions(!isOpenOptions);
  };

  useEffect(() => {
    const handleGetImageProfile = async () => {
      if (token) {
        const { data } = await getImageProfile(token);

        if (data?.results?.length > 0) {
          const image = data.results[0].arquivo;
          setAvatarUser(image);
        }
      }
    };

    handleGetImageProfile();
  }, [token]);

  const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();

  const loginRedirect = () => {
    const { pathname } = location;
    loginRedirectPrivider(pathname);
  };

  const nameSplitOne = (name: string): string => {
    if ((name || '').split(' ').length > 2) {
      return `${name.split(' ')[0]}`;
    }
    return name;
  };

  const nameSplitTwo = (name: string): string => {
    if ((name || '').split(' ').length > 2) {
      return `${name.split(' ')[0]} ${name.split(' ')[1][0]}.`;
    }
    return name;
  };

  const onBeforeNavigateVerifyIsAuthenticated = useCallback(
    (link: string): void => {
      if (token && tokenSiseci) {
        setIsAuth(true);
        window.open(link, '_blank', 'noreferrer');
      } else {
        setIsAuth(false);
      }
    },
    [],
  );

  const renderItems = (): JSX.Element => {
    const menus = [
      {
        titulo: 'Transparência',
        external_url: process.env.REACT_APP_LINK_ACESSO_TRANSPARENCIA,
        icon: <SearchIcon fontSize="small" />,
        isAuth: false,
      },
      {
        titulo: 'Legislação',
        external_url: process.env.REACT_APP_LINK_LEGISLACAO,
        icon: <GavelIcon fontSize='small' />,
        isAuth: false,
      },
      {
        titulo: 'Estrutura da Prefeitura',
        url: '/orgaos',
        icon: <AccountBalanceIcon fontSize="small" />,
        isAuth: false,
      },
      {
        titulo: 'Acessibilidade',
        url: '/acessibilidade',
        icon: <AccessibilityIcon fontSize="small" />,
        isAuth: false,
      },
      {
        titulo: 'Diário Oficial do Município',
        external_url: process.env.REACT_APP_LINK_DIARIO,
        icon: <BookIcon fontSize="small" />,
        isAuth: false,
      },
      {
        titulo: 'Fale com a Ouvidoria',
        url: '/ouvidoria',
        icon: <QuestionAnswerIcon fontSize='small' />,
        isAuth: false,
      },
      {
        titulo: 'Acesso à informação',
        external_url: process.env.REACT_APP_LINK_ACESSO_INFORMACAO,
        url: '/esic',
        icon: <InfoIcon fontSize='small' />,
        isAuth: false,
      },
    ];

    return (
      <>
        {menus.map((menu, index) => (
          <Fragment key={menu.titulo}>
            <ListItem
              onClick={(e: React.SyntheticEvent): void => {
                preventDefault(e);
                if (menu.external_url && menu?.isAuth) {
                  onBeforeNavigateVerifyIsAuthenticated(menu.external_url);
                } else if (menu.external_url && !menu?.isAuth) {
                  window.open(menu.external_url);
                } else {
                  history.push(menu.url);
                }
                handleClose();
              }}
              className={!matches && classes.itemMenu}
              style={{
                padding: matches && 0,
                width: matches && 'auto',
              }}
            >
              <Typography
                className={
                  matches ? classes.linksBoxSubMenu : classes.linksBoxSubMenu
                }
              >
                {menu.icon}
                {menu.titulo}
              </Typography>
            </ListItem>
            {menus.length - 1 === index ? (
              <></>
            ) : (
              <Divider
                style={{
                  backgroundColor: 'rgba(255, 255, 255, 0.16)',
                  display: matches && 'none',
                }}
              />
            )}
          </Fragment>
        ))}
      </>
    );
  };

  const menuWorkspace = [
    {
      name: 'Minha conta',
      url: '/workspace',
      icon: <AccountCircleIcon className={classes.iconMenuWorkspace} />,
    },
    {
      name: 'Meus dados',
      url: '/workspace/meus-dados',
      icon: <ContactMailIcon className={classes.iconMenuWorkspace} />,
    },
    {
      name: 'Atividades',
      url: '/workspace/atividades',
      icon: <PlaylistAddCheckIcon className={classes.iconMenuWorkspace} />,
    },
    {
      name: 'Favoritos',
      url: '/workspace/favoritos',
      icon: <BookmarkIcon className={classes.iconMenuWorkspace} />,
    },
    {
      name: 'Minhas solicitações',
      url: '/workspace/minhas_solicitacoes',
      icon: <PlaylistAddIcon className={classes.iconMenuWorkspace} />,
    },
    {
      name: 'Privacidade',
      url: '/workspace/privacidade',
      icon: <SecurityIcon className={classes.iconMenuWorkspace} />,
    },
  ];

  useEffect(() => {
    refInicio.current.scrollIntoView();
  }, [location]);

  const handleCloseModal = () => {
    if (tokenSiseci) {
      setOpenModal(!openModal);
      setIsAuth(true);
    } else {
      setIsAuth(false);
      setOpenModal(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Salvador Digital</title>
      </Helmet>
      <ModalNotAuth open={!isAuth} onClose={(): void => setIsAuth(true)} />
      {matches && (
        <div
          className={classes.menuPrimary}
          ref={matches ? refInicio : null}
          id="back-to-top-anchor"
        >
          <Box className={classes.contentMenuPrimary}>
            <Box display="flex" gridGap={15}>
              {renderItems()}
            </Box>
            <Box className={classes.boxSocialMedia}>
              <Typography className={classes.titleBoxSocialMedia}>
                Siga-nos:
              </Typography>
              <Box className={classes.boxSocialMediaItems}>
                <IconButton
                  onClick={(): void => {
                    goToPage(linkFace);
                  }}
                  className={classes.btnSocial}
                >
                  <FacebookIcon className={classes.iconSocial} />
                </IconButton>
                <IconButton
                  onClick={(): void => {
                    goToPage(linkInsta);
                  }}
                  className={classes.btnSocial}
                >
                  <InstagramIcon className={classes.iconSocial} />
                </IconButton>
                <IconButton
                  onClick={(): void => {
                    goToPage(linkTwitter);
                  }}
                  className={classes.btnSocial}
                >
                  <TwitterIcon className={classes.iconSocial} />
                </IconButton>
                <IconButton
                  onClick={(): void => {
                    goToPage(linkYoutube);
                  }}
                  className={classes.btnSocial}
                >
                  <YouTubeIcon className={classes.iconSocial} />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </div>
      )}
      <div
        ref={!matches ? refInicio : null}
        id="back-to-top-anchor"
        style={{ top: 0 }}
        className={classes.main}
      >
        <Snackbar
          autoHideDuration={3000}
          onClose={(): void => setMessage('')}
          open={!!message}
        >
          <Alert onClose={(): void => setMessage('')} severity="error">
            {message}
          </Alert>
        </Snackbar>
        <Popover
          className={classes.boxInfo}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {renderItems()}
        </Popover>
        {/* //Popover User */}
        <Popover
          open={Boolean(anchorElUser)}
          anchorEl={anchorElUser}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          style={{
            top: 10,
          }}
        >
          <Box>
            {userData && (
              <MenuItem
                style={{
                  backgroundColor: 'rgba(5, 51, 150, 0.04)',
                  padding: '16px',
                  gap: 10,
                }}
              >
                <Avatar
                  src={avatarUser || null}
                  alt={getInitialsFromName(userData.nome || '')}
                  className={classes.avatar}
                />
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                >
                  <Typography className={classes.textNamePop}>
                    {matchesMobile
                      ? nameSplitTwo(userData?.nome?.toLowerCase())
                      : nameSplitOne(userData?.nome?.toLowerCase())}
                  </Typography>
                  <Typography className={classes.textCpfPop}>
                    {cpfMask(userData.cpf)}
                  </Typography>
                </Box>
              </MenuItem>
            )}
            {!matches && (
              <MenuItem
                onClick={(e): void => handleClick(e, 'user-panel')}
                className={classes.menuItem}
              >
                <DashboardIcon
                  style={{
                    color: colors.accentColor,
                    width: 24,
                    height: 24,
                  }}
                />
                <Typography className={classes.textMenuItem}>
                  Meu Painel
                </Typography>
              </MenuItem>
            )}
            <MenuItem onClick={logoutUser} className={classes.menuItem}>
              <ExitToAppIcon
                style={{
                  color: colors.buttonErrorColor,
                  width: 24,
                  height: 24,
                }}
              />
              <Typography className={classes.textMenuItem}>Sair</Typography>
            </MenuItem>
          </Box>
        </Popover>
        {/* //Popover User Panel */}
        <Popover
          open={Boolean(anchorElUserPanel)}
          anchorEl={anchorElUserPanel}
          onClose={handleClose}
          anchorOrigin={{
            vertical: matches ? 'bottom' : 'top',
            horizontal: matches ? 'right' : 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          style={{
            top: matches ? 10 : 0,
            left: matches ? 0 : -10,
          }}
        >
          <Box padding="8px">
            {userData && (
              <>
                {menuWorkspace.map((menu) => (
                  <LinkRouter
                    key={`key-${menu.name}`}
                    to={menu.url}
                    style={{ textDecoration: 'none' }}
                  >
                    <MenuItem className={classes.menuItem}>
                      {menu.icon}
                      <Typography className={classes.textMenuItem}>
                        {menu.name}
                      </Typography>
                    </MenuItem>
                  </LinkRouter>
                ))}
              </>
            )}
          </Box>
        </Popover>
        <Box
          display="flex"
          flexDirection={!matches ? 'column' : 'row'}
          maxWidth="1320px"
          alignItems="center"
          width="100%"
        >
          <Box
            display="flex"
            alignItems="center"
            width="100%"
            justifyContent="space-between"
            style={{
              padding: `${!matches ? '20px 0px' : '0px'}`,
            }}
          >
            <LinkRouter to="/">
              <img
                className={classes.img}
                alt="logo"
                src={process.env.REACT_APP_LOGO_PRIMARY}
                width="220px"
                height="67px"
              />
            </LinkRouter>
            {!matches ? (
              <IconButton
                onClick={handleOpenOptions} 
                size="medium"
                type="button"
                style={{ padding: 10 }}
              >
                <MoreVertIcon
                  fontSize='inherit'
                  style={{ color: colors.textAccentColor }}
                />
              </IconButton>
            ) : (
              <Box display="flex" alignItems="center" style={{ gap: 48 }}>
                {loading ? (
                  <CircularProgress
                    style={{
                      width: 25,
                      height: 25,
                      color: colors.textAccentColor,
                    }}
                  />
                ) : (
                  <>
                    {userData ? (
                      <Box display="flex" alignItems="center" gridGap="24px">
                        <Button
                          color="primary"
                          onClick={(e): void => handleClick(e, 'user-panel')}
                          className={classes.userPanel}
                        >
                          Meu painel
                        </Button>
                        <Button
                          color="primary"
                          onClick={(e): void => handleClick(e, 'user')}
                          startIcon={(
                            <Avatar
                              src={avatarUser || null}
                              alt={getInitialsFromName(userData.nome || '')}
                              className={classes.avatar}
                            />
                          )}
                          endIcon={(
                            <KeyboardArrowDownIcon
                              style={{ color: colors.textAccentColor }}
                            />
                          )}
                        >
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="flex-start"
                          >
                            <Typography
                              className={classes.textName}
                              color="primary"
                            >
                              {matchesMobile
                                ? nameSplitTwo(userData?.nome?.toLowerCase())
                                : nameSplitOne(userData?.nome?.toLowerCase())}
                            </Typography>
                            <Typography
                              color="primary"
                              className={classes.textCpf}
                            >
                              {cpfMask(userData.cpf)}
                            </Typography>
                          </Box>
                        </Button>
                      </Box>
                    ) : (
                      <Button
                        style={{
                          border: 'none',
                        }}
                        color="default"
                        variant="outlined"
                        onClick={loginRedirect}
                        endIcon={(
                          <KeyboardArrowDownIcon
                            style={{
                              color: colors.textAccentColor,
                              width: 13,
                              height: 20,
                            }}
                          />
                        )}
                      >
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          width={40}
                          height={40}
                          style={{
                            marginRight: 8,
                            border: '0.8px solid #FFFFFF',
                            borderRadius: '50%',
                            color: colors.textAccentColor,
                          }}
                        >
                          <AccountCircleIcon fontSize='medium' />
                        </Box>
                        <Typography className={classes.textButton}>
                          Entrar
                        </Typography>
                      </Button>
                    )}
                  </>
                )}
              </Box>
            )}
          </Box>
          {!matches && (
            <Box
              display="flex"
              alignItems="center"
              width="100%"
              justifyContent="space-between"
              style={{
                padding: '10px 0px',
              }}
            >
              <IconButton onClick={handleOpenMenu} size="medium" type="button">
                <MenuIcon
                  fontSize="medium"
                  style={{ color: colors.textAccentColor, cursor: 'pointer' }}
                />
              </IconButton>
              {loading ? (
                <CircularProgress
                  style={{
                    width: 25,
                    height: 25,
                    color: colors.textAccentColor,
                  }}
                />
              ) : (
                <>
                  {userData ? (
                    <Button
                      color="primary"
                      onClick={(e): void => handleClick(e, 'user')}
                      startIcon={(
                        <Avatar
                          src={avatarUser || null}
                          alt={getInitialsFromName(userData.nome || '')}
                          className={classes.avatar}
                        />
                      )}
                      endIcon={(
                        <KeyboardArrowDownIcon
                          style={{ color: colors.textAccentColor }}
                        />
                      )}
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                      >
                        <Typography
                          className={classes.textName}
                          color="primary"
                        >
                          {matchesMobile
                            ? nameSplitTwo(userData?.nome?.toLowerCase())
                            : nameSplitOne(userData?.nome?.toLowerCase())}
                        </Typography>
                        <Typography color="primary" className={classes.textCpf}>
                          {cpfMask(userData.cpf)}
                        </Typography>
                      </Box>
                    </Button>
                  ) : (
                    <Button
                      style={{
                        border: 'none',
                      }}
                      color="default"
                      variant="outlined"
                      onClick={loginRedirect}
                    >
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        width={40}
                        height={40}
                        style={{
                          marginRight: 8,
                          border: '0.8px solid #FFFFFF',
                          borderRadius: '50%',
                          color: colors.textAccentColor,
                        }}
                      >
                        <AccountCircleIcon fontSize='medium' />
                      </Box>
                      <Typography className={classes.textButton}>
                        Entrar
                      </Typography>
                    </Button>
                  )}
                </>
              )}
            </Box>
          )}
        </Box>
        <Drawer anchor="left" open={isOpenMenu} onClose={handleClose}>
          <Box className={classes.backdropMenu}>
            <Box className={classes.menuHeader}>
              <Typography className={classes.menuHeaderTitle}>Menu</Typography>
              <CloseIcon
                fontSize="medium"
                onClick={handleClose}
                style={{ cursor: 'pointer', color: colors.textAccentColor }}
              />
            </Box>
            <Menu
              handleOpenModal={handleCloseModal}
              handleClose={handleClose}
            />
          </Box>
        </Drawer>
        <Drawer anchor="left" open={isOpenOptions} onClose={handleClose}>
          <Box className={classes.backdropMenu}>
            <Box className={classes.menuHeader}>
              <Typography className={classes.menuHeaderTitle}>
                Mais opções
              </Typography>
              <CloseIcon
                fontSize="medium"
                onClick={handleClose}
                style={{ cursor: 'pointer', color: colors.textAccentColor }}
              />
            </Box>
            <List
              style={{
                padding: 0,
              }}
            >
              {renderItems()}
            </List>
          </Box>
        </Drawer>
        <QuickSchedule
          openModal={openModal}
          handleCloseModal={handleCloseModal}
        />
      </div>
      <TermoDialog />
      <ModalAlertsDialog />
      <AlertAcceptCookies />
      <AlertDataComplements
        handleClose={() => setOpenModalComplements(false)}
        open={openModalComplements}
      />
    </>
  );
}
