import React from "react";

interface CookieIconProps {
  styles?: React.CSSProperties;
  className?: string;
  size?: number;
}

const CookieIcon = ({ size, styles, className }: CookieIconProps) => {
  return (
    <svg
      className={className}
      style={styles}
      fontSize={size}
      focusable="false"
      aria-hidden="true"
      viewBox="0 0 24 24"
      data-testid="CookieIcon"
    >
      <path d="M21.95 10.99c-1.79-.03-3.7-1.95-2.68-4.22-2.98 1-5.77-1.59-5.19-4.56C6.95.71 2 6.58 2 12c0 5.52 4.48 10 10 10 5.89 0 10.54-5.08 9.95-11.01M8.5 15c-.83 0-1.5-.67-1.5-1.5S7.67 12 8.5 12s1.5.67 1.5 1.5S9.33 15 8.5 15m2-5C9.67 10 9 9.33 9 8.5S9.67 7 10.5 7s1.5.67 1.5 1.5-.67 1.5-1.5 1.5m4.5 6c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1"></path>
    </svg>
  );
};

export default CookieIcon;
