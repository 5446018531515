export default function cnpjMask(value: string): string {
  if (value) {
    return value.replace(/\D+/g, '')
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  }

  return '';
}

export function removeCnpjMask(value: string): string {
  return value.replace(/\D/g, '');
}
