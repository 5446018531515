import React, { ReactElement, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { sendCodeForSISECI } from '../../../services/auth';

interface Props {
  children: JSX.Element;
}

export default function BlankLayout(props: Props): ReactElement {
  const { children } = props;
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const url = (window as any).location.search;
    const { pathname } = location;
    const query = new URLSearchParams(url);
    const code: any = query.get('code');
    if (code) {
      document.cookie = `pathname_redirect_gov=${encodeURIComponent(pathname)};path=/`;
      sendCodeForSISECI(code, history);
    }

    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA}`;
    script.addEventListener('load', () => {});
    document.body.appendChild(script);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location?.pathname?.includes("mobile")) {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach(() => {
          const element_01 = document.querySelector('iframe[src*="userway"]') as HTMLElement;
          const element_02 = document.querySelector('#userwayAccessibilityIcon') as HTMLElement;
          const element_user_way = document.querySelector('.userway_buttons_wrapper') as HTMLElement;
          const element = document.getElementById('chat-window-iframe');
          const captcha = document.getElementsByClassName('grecaptcha-badge');
  
          if (element_01) {
            element_01.style.visibility = 'hidden';
            element_01.style.opacity = '0';
          }
  
         if (element) {
         element.style.visibility = 'hidden';
         }
          if (element_02) {
            element_02.style.visibility = 'hidden';
            element_02.style.opacity = '0';
          }
          if (element_user_way) {
            element_user_way.remove();
          }
          if (captcha && captcha.length) {
            (captcha[0] as HTMLElement).remove();
          }
          if (element_01 || element_02 || element_user_way || (captcha && captcha.length)) {
            observer.disconnect();
          }
        });
      });
  
      observer.observe(document.body, { childList: true, subtree: true });
  
      return () => observer.disconnect();
    }
  }, [location.pathname]);

  return (
    <>
      <div>
        {children}
      </div>
    </>
  );
}
