/* eslint-disable import/prefer-default-export */
// /* eslint-disable @typescript-eslint/naming-convention */

import IdentificationActionButton from './IdentificationActionButton';
import IdentificationActions from './IdentificationActions';
import IdentificationHeader from './IdentificationHeader';
import IdentificationOptionCheck from './IdentificationOptionCheck';
import IdentificationOptionsCheck from './IdentificationOptionsCheck';
import IdentificationRoot from './IdentificationRoot';

export const Identification = {
  Root: IdentificationRoot,
  Header: IdentificationHeader,
  Content: IdentificationOptionsCheck,
  Option: IdentificationOptionCheck,
  ContentAction: IdentificationActions,
  Action: IdentificationActionButton,
};
