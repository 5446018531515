import DialogTitle from '@material-ui/core/DialogTitle';
import Theme from '@material-ui/core/styles/MuiThemeProvider';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import TodayIcon from '@material-ui/icons/Today';
import React, { useContext } from 'react';
import colorContext from '../../../../Context/colorContext';

export default function TitleDialog():JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    icon: {
      width: 25,
      height: 25,
      color: colors.textAccentColor,
    },
    header: {
      background: theme.palette.primary.main,
    },
    textHeader: {
      color: colors.textAccentColor,
      display: 'flex',
      alignItems: 'center',
      textTransform: 'uppercase',
      fontSize: theme.typography.pxToRem(14),
      fontFamily: 'Roboto',
      fontWeight: 500,
      width: '90%',
    },
  }));

  const classes = useStyles();
  return (
    <DialogTitle className={classes.header}>
      <Typography className={classes.textHeader}>
        <TodayIcon
          fontSize='large'
          className={classes.icon}
          style={{ marginRight: 15 }}
        />
        Agendamento rápido
      </Typography>
    </DialogTitle>
  );
}
