import React, { useContext, useState } from 'react';
import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { makeStyles, Theme } from '@material-ui/core/styles';
import HomeIcon from '@material-ui/icons/Home';
import SearchIcon from '@material-ui/icons/Search';
import { useHistory } from 'react-router-dom';
import colorContext from '../../../Context/colorContext';

interface Props {
  slug: string;
}

export default function Breadcrumb({
  slug,
}: Props): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme:Theme) => ({
    main: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#fff',
    },
    link: {
      display: 'flex',
    },
    icon: {
      marginRight: theme.spacing(0.5),
      color: '#053396',
      width: 30,
      height: 30,
    },
    inputSearch: {
      height: 50,
      borderRadius: 0,
      width: '100%',
      border: 'none',
      outline: 'none',
      paddingLeft: 16,
      color: colors.textBody,
      background: colors.colorBackground,
      '&::placeholder': {
        color: 'rgba(31, 41, 55, 0.5)',
      },
    },
    buttonSearch: {
      background: '#053396',
      '&:hover': {
        background: '#053396',
      },
    },
    breadcrumbs: {
      flexGrow: 1,
      color: '#053396',
    },
    itemBreadcrumb: {
      color: colors.accentColor,
      display: 'block',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      maxWidth: '300px',
      textTransform: 'capitalize',
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 400,
    },
    iconSearch: {
      color: '#fff',
    },
  }));
  const classes = useStyles();
  const history = useHistory();
  const [textSearch, setTextSearch] = useState<string>('');
  const matches = useMediaQuery('(min-width:790px)');

  const submitSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (textSearch) {
      const formattedSearchText = textSearch.replaceAll('/', '');
      history.push(`/buscar/q=${formattedSearchText}`);
      setTextSearch('');
    }
  };

  return (
    <Box className={classes.main}>
      <Box
        display="flex"
        maxWidth="1320px"
        alignItems={matches ? 'center' : 'initial'}
        width="100%"
        minHeight="56px"
        height="100%"
        justifyContent={matches ? 'flex-end' : 'space-between'}
        padding={matches ? '6px' : '20px'}
        flexDirection={matches ? 'row' : 'column'}
        style={{
          gap: matches ? 0 : 20,
        }}
      >
        <Breadcrumbs separator="/" className={classes.breadcrumbs}>
          <Link
            href="/"
            onClick={(e: React.SyntheticEvent) => { e.preventDefault(); history.push('/'); }}
          >
            <HomeIcon className={classes.icon} />
          </Link>
          <Link
            href="/"
            onClick={(e: React.SyntheticEvent) => { e.preventDefault(); history.push('/'); }}
          >
            <Typography className={classes.itemBreadcrumb}>
              Demanda
            </Typography>
          </Link>
          <Typography className={classes.itemBreadcrumb}>
            {slug.toLowerCase()}
          </Typography>
        </Breadcrumbs>
        <form
          onSubmit={submitSearch}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            flexGrow: 1,
            minWidth: 280,
          }}
        >
          <input
            value={textSearch}
            onChange={(e): void => setTextSearch(e.target.value)}
            className={classes.inputSearch}
            style={{
              maxWidth: matches ? 300 : '',
              width: '100%',
              borderRadius: '4px 0px 0px 4px',
              border: '1px solid #053396',
            }}
            placeholder="O que você procura ?"
          />
          <IconButton
            type="submit"
            className={classes.buttonSearch}
            style={{
              borderRadius: '0px 4px 4px 0px',
            }}
          >
            <SearchIcon fontSize='default' className={classes.iconSearch} />
          </IconButton>
        </form>
      </Box>
    </Box>
  );
}
