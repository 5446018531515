import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import { makeStyles, Theme } from '@material-ui/core/styles';
import QrCode from 'qrcode.react';
import { useParams } from 'react-router-dom';
import PrintIcon from '@material-ui/icons/Print';
// Create styles
const useStyles = makeStyles((theme: Theme) => ({
  boxHeader: {
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    padding: 20,
  },
  textName: {
    fontSize: '24px',
    lineHeight: '24px',
    letterSpacing: '0.1px',
  },
  textCpf: {
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '24px',
    textTransform: 'capitalize',
  },
  textUnidade: {
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '23px',
    color: '#737B7D',
    paddingBottom: '20px',
    paddingTop: '20px',
  },
  textService: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(24),
    color: '#1F2937',
    marginBottom: 24,
  },
  protocoloText: {
    fontSize: theme.typography.pxToRem(13),
    fontWeight: 400,
    marginBottom: 8,
  },
}));

interface ParamsType {
  codigo?:string
  protocolo:string
}

export default function ManifestationPrint(): JSX.Element {
  const classes = useStyles();
  const { codigo, protocolo }: ParamsType = useParams();

  useEffect(() => {
    const captcha = document.getElementsByClassName('grecaptcha-badge');

    if (captcha && captcha.length) {
      captcha[0].remove();
    }
  }, []);

  useEffect(() => {
    const enable = document.getElementsByClassName('enabled');
    if (enable && enable.length) {
      enable[0].remove();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codigo, protocolo]);

  useEffect(() => {
    if (codigo || protocolo) {
      window.print();
    }
  }, [codigo, protocolo]);

  return (
    <Box style={{ padding: 20 }} id="print">
      <Box>
        <Grid
          container
          justify="space-between"
          alignItems="flex-start"
          className={classes.boxHeader}
        >
          <Grid item>
            <Typography className={classes.textName}>Manifestação registrada com sucesso!</Typography>
            <Typography className={classes.textCpf}>Obrigado pela sua participação</Typography>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item style={{ paddingBottom: '20px' }}>
            <Typography
              className={classes.textUnidade}
            >
              {codigo
                ? 'Para acompanhar o andamento da sua manifestação, anote e guarde o número de Protocolo e o código de Acesso a seguir:'
                : 'Para acompanhar o andamento da sua manifestação, anote e guarde o número de Protocolo a seguir:'}
            </Typography>
            <Typography
              className={classes.protocoloText}
            >
              Protocolo
            </Typography>
            <Typography
              className={classes.textService}
            >
              {protocolo}
            </Typography>
            {codigo && (
            <>
              <Typography
                className={classes.protocoloText}
              >
                Código de acesso
              </Typography>
              <Typography
                className={classes.textService}
              >
                {codigo}
              </Typography>
            </>
            )}

            <Grid item xs={4}>
              <QrCode
                value={`${process.env.REACT_APP_URL}/workspace/minhas_manifestacoes`}
                size={180}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Grid item>
            <Button variant="contained" color="primary" className="print_none" onClick={() => window.print()}>
              <style>
                {'@media print {.print_none{display: none;}}'}
              </style>
              {' '}
              <PrintIcon />
              {' '}
              <Typography>IMPRIMIR</Typography>
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
