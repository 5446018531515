/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable default-case */
import { makeStyles } from '@material-ui/styles';
import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';

import Box from '@material-ui/core/Box';
import Icon from '@material-ui/core/Icon';
import Step from '@material-ui/core/Step';
import { StepIconProps } from '@material-ui/core/StepIcon';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import Theme from '@material-ui/core/styles/MuiThemeProvider';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { FileObject } from 'material-ui-dropzone';
import DescriptionIcon from '@material-ui/icons/Description';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import refContext from '../../Context/refContext';
// eslint-disable-next-line import/no-cycle
import {
  Conclusion, Description, Identification, Receiver,
} from './Steps';
import Registry from './Registry';
import Breadcrumb from './Breadcrumb';
import theme from '../../services/theme-service';
import Terms from './Steps/Terms';
import IconTitle from './Icon';

export interface EsicType {
  id: number;
  titulo: string;
}
export interface EsicData {
  codigo_anonimo?: string;
  tipo_manifestacao?: EsicType;
  tipo_identificacao?: 'Anônimo' | 'Identificado' | 'Sigiloso';
  canal_entrada?: string;
  nome?: string;
  cpf?: string;
  email?: string;
  orgao?: {
    nome: string;
    slug: string;
  };
  servico?: {
    nome: string;
    slug: string;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  assunto?: any;
  conteudo?: string;
  arquivos?: FileObject[];
  estado?: string;
  cidade?: string;
  local?: string;
  envolvidos?: {
    nome: string;
    empresa: string;
    cargo: string;
  }[];
  aceite?: number;
  tipo_demanda?: string;
  protocolo_relacionado?: string;
  complemento?: string;
}

interface EsicCodesProps {
  code: string;
  protocol: string;
}

const stepsInfo = [
  {
    title: 'Identificação',
    icon: ContactMailIcon,
  },
  {
    title: 'Termo',
    icon: DescriptionIcon,
  },
  {
    title: 'Descrição',
    icon: DescriptionIcon,
  },
  {
    title: 'Conclusão',
    icon: CheckCircleIcon,
  },
];

export default function NewEsic(): JSX.Element {
  const useStyles = makeStyles(() => ({
    main: {
      width: '100%',
      minHeight: 500,
      backgroundColor: '#fff',
      display: 'flex',
      flexDirection: 'column',
    },
    boxTitle: {
      width: '100%',
      backgroundColor: theme.palette.primary.main,
      minHeight: 276,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 40,
      marginBottom: 56,
      [theme.breakpoints.down(660)]: {
        marginBottom: 24,
        minHeight: 208,
        gap: 32,
      },
    },
    title: {
      fontSize: 36,
      fontWeight: 800,
      color: '#fff',
      [theme.breakpoints.down(660)]: {
        fontSize: theme.typography.pxToRem(20),
      },
    },
    iconTitle: {
      width: 42,
      height: 56,
      color: '#fff',
      [theme.breakpoints.down(660)]: {
        width: 32,
        height: 32,
      },
    },
    boxStepper: {
      maxWidth: 920,
      width: '100%',
      margin: '0 auto',
    },
    stepper: {
      backgroundColor: '#F6F8FC',
      padding: '8px 10px',
      borderRadius: 80,
      justifyContent: 'space-between',
      '& .MuiStepConnector-root': {
        width: 5,
      },
    },
    boxIcon: {
      width: 48,
      height: 48,
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.primary.main,
    },
    stepIcon: {
      fontSize: 24,
    },
    stepCount: {
      fontSize: theme.typography.pxToRem(12),
      fontWeight: 400,
      color: '#333',
    },
    stepTitle: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 600,
    },
    stepMobile: {
      height: 180,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 10,
      backgroundColor: '#F6F8FC',
      [theme.breakpoints.down(660)]: {
        height: 130,
      },
    },
    stepIconMobile: {
      fontSize: 30,
      color: '#fff',
      [theme.breakpoints.down(660)]: {
        fontSize: 18,
      },
    },
    boxIconMobile: {
      width: 60,
      height: 60,
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.primary.main,
      [theme.breakpoints.down(660)]: {
        width: 40,
        height: 40,
      },
    },
    stepCountMobile: {
      fontSize: theme.typography.pxToRem(14),
      [theme.breakpoints.down(660)]: {
        fontSize: theme.typography.pxToRem(12),
      },
    },
    stepTitleMobile: {
      fontSize: theme.typography.pxToRem(20),
      [theme.breakpoints.down(660)]: {
        fontSize: theme.typography.pxToRem(16),
      },
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 20,
    },
  }));
  const classes = useStyles();
  const { refRegistryType } = useContext(refContext);
  const matches = useMediaQuery('(min-width:850px)');
  const [activeStep, setActiveStep] = useState(0);
  const [esicData, setEsicData] = useState<EsicData>();
  const [esicCodes, setEsicCodes] = useState<EsicCodesProps>();

  const handleChangeEsicData = (newData: EsicData) => {
    const data = { ...esicData, ...newData };
    setEsicData(data);
  };

  const handleChangeEsicCode = (code: string, protocol: string) => {
    setEsicCodes({ code, protocol });
  };

  useEffect(() => {
    if (refRegistryType) {
      refRegistryType.current.scrollIntoView();
    }
  }, [activeStep, refRegistryType]);

  const iconCustomized = (IconStepper: any, step: StepIconProps) => {
    const { active, completed } = step;
    return (
      <div
        className={classes.boxIcon}
        style={{
          backgroundColor:
            active || completed
              ? theme.palette.primary.main
              : '#FFFFFF',
        }}
      >
        <IconStepper
          className={classes.stepIcon}
          style={{
            color: active || completed ? '#fff' : '#DAE1EF',
          }}
        />
      </div>
    );
  };

  const controlStep = (action: 'prev' | 'next') => {
    // eslint-disable-next-line default-case
    switch (action) {
      case 'prev':
        setActiveStep((state) => state - 1);
        break;
      case 'next':
        setActiveStep((state) => state + 1);
        break;
    }
  };

  return (
    <div className={classes.main} ref={refRegistryType}>
      <>
        <Breadcrumb slug="Lei de Acesso à informação" />
        <Box className={classes.boxTitle}>
          <Box className={classes.content}>
            <IconTitle />
            <Typography className={classes.title}>
              Lei de Acesso à informação
            </Typography>
          </Box>
        </Box>
        {activeStep <= 3 ? (
          <>
            <Box className={classes.boxStepper}>
              {matches ? (
                <Stepper
                  activeStep={activeStep}
                  classes={{ root: classes.stepper }}
                  connector={<></>}
                >
                  {stepsInfo.map((step, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Step key={index}>
                      <StepLabel
                        StepIconComponent={(stepIcon) => iconCustomized(step.icon, stepIcon)}
                      >
                        <Box>
                          {index <= activeStep && (
                            <span className={classes.stepCount}>
                              Passo
                              {' '}
                              {index + 1}
                              /
                              {stepsInfo.length}
                            </span>
                          )}
                          <Typography
                            className={classes.stepTitle}
                            style={{
                              color:
                                index <= activeStep
                                  ? '#333'
                                  : '#706E7A',
                            }}
                          >
                            {step.title}
                          </Typography>
                        </Box>
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              ) : (
                <Box className={classes.stepMobile}>
                  {stepsInfo.map((step, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={index}>
                      {index === activeStep && (
                        <>
                          <div className={classes.boxIconMobile}>
                            <Icon
                              component={step.icon}
                              className={classes.stepIconMobile}
                            />
                          </div>
                          <span
                            className={`${classes.stepCount} ${classes.stepCountMobile}`}
                          >
                            Passo
                            {' '}
                            {index + 1}
                            /
                            {stepsInfo.length}
                          </span>
                          <Typography
                            className={`${classes.stepTitle} ${classes.stepTitleMobile}`}
                            style={{
                              color:
                                index <= activeStep
                                  ? '#333'
                                  : '#706E7A',
                            }}
                          >
                            {step.title}
                          </Typography>
                        </>
                      )}
                    </div>
                  ))}
                </Box>
              )}

              <Box>
                {activeStep === 0 && (
                  <Identification
                    controlStep={controlStep}
                    changeEsicData={handleChangeEsicData}
                  />
                )}
                {activeStep === 1 && (
                  <Terms
                    controlStep={controlStep}
                    changeEsicData={handleChangeEsicData}
                  />
                )}
                {activeStep === 2 && (
                  <Description
                    controlStep={controlStep}
                    changeEsicData={handleChangeEsicData}
                  />
                )}
                {activeStep === 3 && (
                  <Conclusion
                    controlStep={controlStep}
                    esicData={esicData}
                    changeEsicCodes={handleChangeEsicCode}
                  />
                )}
              </Box>
            </Box>
          </>
        ) : (
          <>
            <Registry
              typeIdentification={esicData.tipo_identificacao}
              esicCode={esicCodes}
              setActiveStep={setActiveStep}
            />
          </>
        )}
      </>
    </div>
  );
}
