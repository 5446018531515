import React, { useContext, useEffect, useRef } from 'react';
import { getGeolocationByLatLng } from '../../../../../../services/geoLocation';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles, Theme } from '@material-ui/core/styles';
import colorContext from '../../../../../../Context/colorContext';
import  Box  from '@material-ui/core/Box';

interface Geolocation {
  latitude: number;
  longitude: number;
}

interface MapComponentProps {
  geolocation?: Geolocation;
  setCepNumber?: (cep: string) => void;
  searchLocalization?: (shouldSearch: boolean) => void;
  cepNumber?: string;
  precision?: boolean;
  setPrecision?: (precision: boolean | ((prev: boolean) => boolean)) => void; 
  setGeolocation?: (geolocation: Geolocation) => void;
  setLocal?: (local: any) => void;
  setCity?: (city: any) => void;
  setNeighborhood?: (neighborhood: any) => void;
}

interface LeafletMapDivElement extends HTMLDivElement {
  _leaflet_map?: any; 
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  inputCheckbox: {
    color: theme.palette.text.primary,
    fontSize: 16,
    fontWeight: 500,
    fontFamily: 'Roboto',
  },
}));

const MapComponent: React.FC<MapComponentProps> = ({
  geolocation,
  setCepNumber,
  searchLocalization,
  cepNumber,
  precision,
  setPrecision,
  setGeolocation,
  setLocal,
  setCity,
  setNeighborhood,
}) => {
  const { colors } = useContext(colorContext);
  const classes = useStyles();
  const mapRef = useRef<LeafletMapDivElement | null>(null);
  const markerRef = useRef<any>(null);

  const getCEP = async (latitude: number, longitude: number) => {
    try {
      const { data } = await getGeolocationByLatLng(latitude, longitude);
      if (data) {
        setCepNumber(data.address.postcode);
        setCity(data.address.city)
        setNeighborhood(data.address.suburb)
        setLocal(data.address.road);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addMarkerAndCenterMap = (lat: number, lng: number, map: any) => {
    if (markerRef.current) {
      map.removeLayer(markerRef.current);
    }

    markerRef.current = (window as any).L.marker([lat, lng])
      .addTo(map)
      // .bindPopup(`Você clicou aqui: ${lat}, ${lng}`)
      // .openPopup();
    getCEP(lat, lng);
    map.setView([lat, lng], 16);
  };

  useEffect(() => {
    const L = (window as any).L;

    if (!L) {
      console.error('Leaflet não está disponível.');
      return;
    }

    if (!mapRef.current || !precision) {
      return;
    }

    const map = L.map(mapRef.current).setView(
      [geolocation.latitude || -12.9714, geolocation.longitude || -38.5014],
      16
    );

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(map);

    const bounds = [
      [-16.0, -39.0],
      [-11.0, -37.0],
    ];
    map.setMaxBounds(bounds);
    map.on('drag', () => map.panInsideBounds(bounds, { animate: false }));

    if (geolocation.latitude && geolocation.longitude) {
      addMarkerAndCenterMap(geolocation.latitude, geolocation.longitude, map);
    }

    const handleMapClick = (e: any) => {
      const { lat, lng } = e.latlng;
      if (lat !== geolocation.latitude || lng !== geolocation.longitude) {
        setGeolocation({ latitude: lat, longitude: lng });
      }
      addMarkerAndCenterMap(lat, lng, map);
    };

    map.on('click', handleMapClick);

    (mapRef.current as LeafletMapDivElement)._leaflet_map = map;

    return () => {
      map.off('click', handleMapClick);
      if (markerRef.current) {
        map.removeLayer(markerRef.current);
      }
      map.remove();
    };
  }, [geolocation, precision]);

  useEffect(() => {
    if (cepNumber) {
      searchLocalization(false);
    }
  }, [cepNumber]);

  return (
    <Box className={classes.container}>
      <FormControlLabel
        className={classes.inputCheckbox}
        control={
          <Checkbox
            checked={precision}
            onChange={() => setPrecision((prevState) => !prevState)}
            name="precision"
            style={{ color: colors.accentColor }}
          />
        }
        label=" Selecionar local exato no mapa."
      />
      {precision && (
        <div
          ref={mapRef}
          style={{ height: '250px', width: '100%' }}
        ></div>
      )}
    </Box>
  );
};

export default MapComponent;
