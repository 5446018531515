import React, { useContext } from 'react';
import Box from '@material-ui/core/Box';

import { makeStyles, Theme } from '@material-ui/core/styles';
import { SearchI } from '../../../SearchService';
import colorContext from '../../../../Context/colorContext';
import { UserData } from '../../../../services/user';
import getCookie from '../../../../utils/getCookies';

interface Props {
  service: SearchI;
}

export default function ServiceDetails({
  service,
}: Props): JSX.Element {
  const { colors } = useContext(colorContext);

  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      backgroundColor: colors.colorBackground,
    },
    information: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      maxWidth: '1320px',
      margin: '80px 0px',
      padding: '0px 10px',
      [theme.breakpoints.down(660)]: {
        padding: '0px 20px',
        marginTop: 40,
      },
    },
    iframe: {
      border: `1px solid ${colors.borderShadowsColor}`,
      borderRadius: 8,
      backgroundColor: '#F4EFE3',
    },
  }));
  const classes = useStyles();

  function removeAcento(text) {
    !text ? text = ''
      : text = text.toLowerCase();
    text = text.replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'a');
    text = text.replace(new RegExp('[ÉÈÊ]', 'gi'), 'e');
    text = text.replace(new RegExp('[ÍÌÎ]', 'gi'), 'i');
    text = text.replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o');
    text = text.replace(new RegExp('[ÚÙÛ]', 'gi'), 'u');
    text = text.replace(new RegExp('[Ç]', 'gi'), 'c');

    return text;
  }

  const paserUrl = (url: string) => {
    const {
      REACT_APP_URL_INTEGRACAO_SERV_FALA,
      REACT_APP_CHAVE_INTEGRACAO_SERV_FALA,
    } = process.env;

    if (!REACT_APP_URL_INTEGRACAO_SERV_FALA || !REACT_APP_CHAVE_INTEGRACAO_SERV_FALA) return url;

    const dataStorage: string | null = getCookie('gov_user_data');
    const userData: UserData | null = dataStorage
      ? JSON.parse(dataStorage)
      : null;
    const { cpf } = userData;
    url = url.replace('@fala_url', REACT_APP_URL_INTEGRACAO_SERV_FALA);
    url = url.replace('@user_cpf', cpf);
    url = url.replace('@service_name', `${removeAcento(service.titulo)}`);
    url = url.replace('@chave_fala', `chave=${REACT_APP_CHAVE_INTEGRACAO_SERV_FALA}`);
    return url;
  };

  return (
    <Box className={classes.main}>
      <Box className={classes.information}>
        <iframe
          src={paserUrl(service.url_externo)}
          style={
          {
            width: '100%',
            minHeight: '514px',
            height: '70vh',
          }
         }
          title="servico_integrado"
          className={classes.iframe}
        />
      </Box>
    </Box>
  );
}
