import React, { ReactElement } from 'react';
import MetaTags from 'react-meta-tags';

export default function Tags(): ReactElement {
  return (
    <div className="wrapper">
      <MetaTags>
        <title>Salvador Digital</title>
        <link rel="icon" type="image/png" sizes="32x32" href={process.env.REACT_APP_TAG_FAVICON ?? '/favicon.ico'} />
      </MetaTags>
    </div>
  );
}
