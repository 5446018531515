import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  serviceSelectedTextTitle: {
    fontSize: theme.typography.pxToRem(12),
    textTransform: 'uppercase',
    fontWeight: 400,
    fontFamily: 'Roboto',
    color: theme.palette.primary.main,
    marginTop: 25,
    marginBottom: 12,
  },
  boxNextOptions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '10px',
    marginBottom: 24,
  },
  serviceSelectedText: {
    color: '#1F2937',
    fontWeight: 500,
    fontFamily: 'Roboto',
    fontSize: theme.typography.pxToRem(16),
  },
  serviceSelectedSubText: {
    color: '#1F2937A3',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
  },
  iconTrash: {
    width: 24,
    height: 24,
    color: '#E53535',
  },
}));

export default useStyles;
