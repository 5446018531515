import { AxiosResponse } from 'axios';
import { format } from 'date-fns';
import { apisiseci } from './api';

export const checarReagendamento = (
  identificador: string,
): Promise<AxiosResponse> => apisiseci
  .get(`cms/reagendamento/checar/?identificador=${identificador}`);

export const reagendar = (
  date: Date,
  hora: string,
  identificador: string,
): Promise<AxiosResponse> => {
  const data = format(date, 'yyyy-MM-dd');
  return apisiseci.post('cms/reagendamento/agendar/', {
    data,
    hora,
    identificador,
  });
};

export const checarHorariosReagendamento = (
  identificador: string,
  date: Date,
): Promise<AxiosResponse> => {
  const data = format(date, 'yyyy-MM-dd');

  return apisiseci
    .get(`cms/reagendamento/checar-horario/?identificador=${identificador}&data=${data}`);
};

export default { checarReagendamento, reagendar, checarHorariosReagendamento };
