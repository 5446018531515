/* eslint-disable react/no-unescaped-entities */
/* eslint-disable import/no-cycle */
import Box from '@material-ui/core/Box';
import Theme from '@material-ui/core/styles/MuiThemeProvider';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/styles';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import colorContext from '../../../Context/colorContext';

interface RegistryProps {
  typeIdentification: 'Identificado' | 'Sigiloso';
  demandCode: {
    code?: string;
    protocol: string;
  };
}

export default function Registry({
  typeIdentification,
  demandCode,
}: RegistryProps): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    boxInformation: {
      maxWidth: 1320,
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      padding: '0px 10px 122px 10px',
      margin: '24px auto 0px auto',
      [theme.breakpoints.down(660)]: {
        marginTop: 40,
        flexDirection: 'column-reverse',
        alignItems: 'center',
        padding: '0px 16px 40px 16px',
      },
    },
    title: {
      fontSize: theme.typography.pxToRem(36),
      fontWeight: 700,
      color: colors.textBody,
      [theme.breakpoints.down(660)]: {
        fontSize: theme.typography.pxToRem(24),
        textAlign: 'center',
      },
    },
    thank: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 400,
      color: colors.textBody,
      display: 'block',
      textAlign: 'left',
      [theme.breakpoints.down(660)]: {
        textAlign: 'center',
      },
    },
    codeTitle: {
      fontSize: theme.typography.pxToRem(13),
      color: colors.textBody,
      fontWeight: 400,
      [theme.breakpoints.down(660)]: {
        fontSize: theme.typography.pxToRem(12),
        textAlign: 'center',
      },
    },
    code: {
      fontSize: theme.typography.pxToRem(24),
      color: colors.textBody,
      fontWeight: 500,
      [theme.breakpoints.down(660)]: {
        textAlign: 'center',
      },
    },
    wanted: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 400,
      color: colors.textBody,
      display: 'block',
      margin: '24px 0px 57px 0px',
      maxWidth: 600,
      lineHeight: '25.6px',
      [theme.breakpoints.down(768)]: {
        fontSize: theme.typography.pxToRem(15),
      },
      [theme.breakpoints.down(660)]: {
        textAlign: 'center',
      },
    },

    buttonGroup: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: theme.typography.pxToRem(14),
    },

    button: {
      minWidth: 258,
      textAlign: 'center',
      backgroundColor: colors.accentColorBackground,
      textDecoration: 'none',
      border: 'none',
      borderRadius: 4,
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 600,
      color: colors.textAccentColor,
      textTransform: 'uppercase',
      padding: '12px 30px',
      fontFamily: 'Roboto',
      cursor: 'pointer',
      [theme.breakpoints.down(660)]: {
        display: 'block',
        width: '100%',
        padding: '12px 0px',
      },
    },
    boxQuery: {
      maxWidth: 1320,
      width: '100%',
      margin: '40px auto 0px auto',
      paddingBottom: '56px',
      padding: '0px 10px',
    },
    queryTitle: {
      fontSize: theme.typography.pxToRem(24),
      color: colors.textBody,
      fontWeight: 700,
      marginBottom: 16,
      [theme.breakpoints.down(660)]: {
        fontSize: theme.typography.pxToRem(18),
        textAlign: 'center',
      },
    },
    queryDescription: {
      fontSize: theme.typography.pxToRem(16),
      color: colors.textBody,
      fontWeight: 500,
      [theme.breakpoints.down(660)]: {
        fontSize: theme.typography.pxToRem(15),
        textAlign: 'center',
      },
    },
    queryLink: {
      fontWeight: 500,
      color: colors.textBody,
    },
    ImageConfirmed: {
      width: 398,
      height: 398,
      [theme.breakpoints.down(768)]: {
        width: 330,
        height: 330,
      },
      [theme.breakpoints.down(660)]: {
        width: 215,
        height: 215,
      },
    },
  }));
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      <Box className={classes.boxInformation}>
        <Box marginTop="50px">
          <Typography className={classes.thank} variant="overline">
            Obrigado pela sua participação
          </Typography>
          <Typography className={classes.title}>
            Demanda registrada com sucesso
          </Typography>
          {typeIdentification === 'Identificado' && (
            <>
              <span className={classes.wanted}>
                Em breve você será notificado pelo e-mail do andamento do processo de resposta de sua demanda,
                acompanhe todas as suas demandas pelo sistema, na opção Minhas Demandas ou clique no botão abaixo:
              </span>
              <Box margin="31px 0px 40px 0px">
                <Typography className={classes.codeTitle}>
                  Número de protocolo
                </Typography>
                <Typography
                  className={classes.code}
                  style={{ marginBottom: 24 }}
                >
                  {demandCode?.protocol || '123121321'}
                </Typography>
              </Box>
              <Box component="div" className={classes.buttonGroup}>
                <button
                  className={classes.button}
                  type="button"
                  onClick={() => history.push('/workspace/minhas_solicitacoes')}
                >
                  ir para minhas demandas
                </button>
                <a
                  href={`/demanda/${demandCode?.protocol}/imprimir`}
                  className={classes.button}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Salvar Comprovante
                </a>
              </Box>
            </>
          )}
          {typeIdentification !== 'Identificado' && (
            <>
              <span className={classes.wanted} style={{ marginBottom: 0 }}>
                Para acompanhar o andamento da sua solicitação, anote e guarde
                o Número de Protocolo e o Código de Acesso a seguir:
              </span>
              <Box margin="31px 0px 40px 0px">
                <Typography className={classes.codeTitle}>
                  Número de protocolo
                </Typography>
                <Typography
                  className={classes.code}
                  style={{ marginBottom: 24 }}
                >
                  {demandCode?.protocol || '123121321'}
                </Typography>
                <Typography className={classes.codeTitle}>
                  Código de acesso
                </Typography>
                <Typography className={classes.code}>
                  {demandCode?.code || '123121321'}
                </Typography>
              </Box>
              <a
                href={`/demanda/${demandCode?.protocol}/imprimir`}
                className={classes.button}
                target="_blank"
                rel="noopener noreferrer"
              >
                Salvar Comprovante
              </a>
            </>
          )}
        </Box>
        <img
          src="/assets/images/confirmed.svg"
          alt="Imagem de ilustrando registro da manifestação"
          className={classes.ImageConfirmed}
        />
      </Box>
      {/* {typeIdentification !== 'Identificado' && (
        <>
          <Divider />
          <Box className={classes.boxQuery}>
            <Typography className={classes.queryTitle}>
              Para consultar sua manifestação
            </Typography>
            <Typography className={classes.queryDescription}>
              Vá em
              {' '}
              <Link to="/ouvidoria" className={classes.queryLink}>
                Consultar Manifestação
              </Link>
              , e informe o Número de Protocolo e o Código de Acesso da
              Manifestação.
            </Typography>
          </Box>
        </>
      )} */}
    </>
  );
}
