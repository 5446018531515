/* eslint-disable no-console */
/* eslint-disable import/no-cycle */
/* eslint-disable react/no-danger */
/* eslint-disable default-case */
import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Theme from '@material-ui/core/styles/MuiThemeProvider';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import colorContext from '../../../Context/colorContext';
import { getTerms } from '../../../services/ouvidoria';
import { DemandData } from '..';
import { sanitizeHtml } from '../../../utils/sanitizeHtml';

interface TermsProps {
  controlStep: (action: 'prev' | 'next') => void;
  changeDemandData: (data: DemandData) => void;
  demandData: DemandData;
}

export default function Terms({
  controlStep,
  changeDemandData,
  demandData,
}: TermsProps): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    boxStepContent: {
      padding: '0px 10px',
      marginTop: 48,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    actionTitle: {
      fontSize: theme.typography.pxToRem(24),
      fontWeight: 500,
      textAlign: 'center',
      color: colors.textBody,
      marginBottom: 48,
      [theme.breakpoints.down(660)]: {
        padding: '0px 16px',
        fontSize: theme.typography.pxToRem(18),
      },
    },
    ticket: {
      display: 'flex',
      flexDirection: 'column',
      backgroundImage: 'url(/assets/images/ticket.png)',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% 100%',
      padding: '0px 10px',
      [theme.breakpoints.down(600)]: {
        backgroundImage: 'url(/assets/images/ticket-mobile.png)',
        padding: '0px 16px',
      },
    },
    ticketMaxContent: {
      minHeight: 341,
      maxHeight: 400,
      overflow: 'hidden',
    },
    ticketMinContent: {
      minHeight: 341,
      overflow: 'auto',
    },
    grid: {
      padding: '40px 80px',
      [theme.breakpoints.down(721)]: {
        padding: '20px 40px',
      },
      [theme.breakpoints.down(600)]: {
        padding: '56px 47px 64px 47px',
      },
    },
    informationTitle: {
      fontSize: theme.typography.pxToRem(14),
      color: colors.textBody,
      fontWeight: 700,
      display: 'block',
      marginBottom: 7,
      [theme.breakpoints.down(600)]: {
        textAlign: 'center',
      },
    },
    description: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 400,
      color: colors.textBody,
      display: 'block',
      wordBreak: 'break-word',
      [theme.breakpoints.down(600)]: {
        textAlign: 'center',
      },
    },
    manifestationDescription: {
      maxHeight: 110,
      overflow: 'hidden',
      whiteSpace: 'pre-line',
    },
    boxButton: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 24,
      gap: 16,
      paddingBottom: '56px',
    },
    button: {
      padding: '12px 43px',
      border: 'none',
      borderRadius: 4,
      textTransform: 'uppercase',
      fontFamily: 'Roboto',
      fontSize: theme.typography.pxToRem(16),
      cursor: 'pointer',
      fontWeight: 500,
      [theme.breakpoints.down(660)]: {
        width: '100%',
        padding: '12px 0px',
      },
    },
    forwardButton: {
      color: colors.textAccentColor,
      backgroundColor: colors.accentColorBackground,
    },
    buttonMore: {
      padding: '10px 43px',
      border: '1px solid #DAE1EF',
      borderRadius: 4,
      textTransform: 'uppercase',
      fontFamily: 'Roboto',
      fontSize: theme.typography.pxToRem(14),
      cursor: 'pointer',
      marginTop: 2,
      backgroundColor: '#F6F8FC',
      width: '100%',
    },
    backButton: {
      display: 'flex',
      alignItems: 'center',
      gap: 11,
      padding: ' 8px, 22px, 8px, 16px',
      border: 'none',
      backgroundColor: colors.colorBackground,
      color: colors.textBody,
      [theme.breakpoints.down(660)]: {
        justifyContent: 'center',
      },
    },
    icon: {
      width: 18,
      height: 18,
      color: colors.textBody,
    },
    moreContent: {
      display: 'block',
      textAlign: 'left',
      [theme.breakpoints.down(600)]: {
        textAlign: 'center',
      },
    },
  }));
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [showMore, setShowMore] = useState<boolean>(false);

  const [term, setTerm] = useState<{
    ativo: boolean;
    id: number;
    texto: string;
    titulo: string;
    versao: string;
  }>();
  const requestTems = async () => {
    try {
      setLoading(true);
      const { data } = await getTerms();
      if (data.length > 0) {
        setTerm(data[0]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const aceptTerm = () => {
    setLoading(true);
    if (term?.id) {
      changeDemandData({
        ...demandData,
        aceite: term?.id,
      });
      controlStep('next');
      setLoading(false);
    }
  };

  const handleShowMore = useCallback(() => setShowMore((oldState) => !oldState), []);

  const renderContent = useCallback(() => {
    if (showMore) {
      return (
        <div dangerouslySetInnerHTML={
        { __html: sanitizeHtml(term?.texto) || '...' }
      }
        />
      );
    }

    const LIMIT_TEXT = 1500;
    return (
      <div dangerouslySetInnerHTML={
        { __html: sanitizeHtml(`${term?.texto?.substring(0, LIMIT_TEXT)}...`) }
      }
      />
    );
  }, [showMore, term]);

  useEffect(() => {
    requestTems();
  }, []);

  return (
    <Box className={classes.boxStepContent}>
      <Typography className={classes.actionTitle}>
        Termo de aceite
      </Typography>
      <Box
        className={`${classes.ticket} ${showMore ? classes.ticketMinContent : classes.ticketMaxContent}`}
      >
        <Grid container className={classes.grid}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography>
              {term?.titulo || '...'}
              {' '}
              - Versão
              {' '}
              {term?.versao || '...'}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {renderContent()}
          </Grid>
        </Grid>
      </Box>
      <button
        type="button"
        className={classes.buttonMore}
        onClick={handleShowMore}
      >
        {showMore ? 'Mostrar menos' : 'Mostrar mais'}
      </button>
      <Box className={classes.boxButton}>
        <button
          type="button"
          onClick={() => controlStep('prev')}
          className={`${classes.button} ${classes.backButton}`}
          disabled={loading}
        >
          <ArrowBackIcon className={classes.icon} />
          Voltar
        </button>
        <button
          type="button"
          onClick={aceptTerm}
          className={`${classes.button} ${classes.forwardButton}`}
        >
          {loading ? (
            <CircularProgress
              style={{ width: 15, height: 15, color: '#fff' }}
            />
          ) : (
            'Aceitar'
          )}
        </button>
      </Box>
    </Box>
  );
}
