export function cepMask(cep: string): string {
  if (cep) {
    const format = cep.replace(/\D/g, '');

    return format.replace(/^(\d{5})(\d)/, '$1-$2');
  }

  return '';
}

export default { cepMask };
