import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { useLocation } from 'react-router-dom';
import colorContext from '../../../../Context/colorContext';

interface Props {
  open: boolean;
  handleClose: () => void;
}

export default function AlertDataComplements({
  open,
  handleClose,
}: Props): JSX.Element {
  const { colors } = useContext(colorContext);

  const location = useLocation();
  const useStyles = makeStyles(() => ({
    boxHeader: {
      backgroundColor: colors.badgeColor,
    },
    textName: {
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.1px',
      color: colors.textAccentColor,
    },
    textUnidade: {
      fontWeight: 'normal',
      fontSize: '18px',
      lineHeight: '23px',
      textTransform: 'capitalize',
      color: '#737B7D',
      paddingBottom: '20px',
      paddingTop: '20px',
    },
    actions: {
      backgroundColor: colors.colorBackgroundSecundary,
    },
    btnConfirm: {
      backgroundColor: colors.badgeColor,
      border: 'none',
      color: '#FFFFFF',
      borderRadius: '0',
      padding: '10px',
      boxShadow: 'none',
    },
    btnClose: {
      backgroundColor: '#737b7f',
      border: 'none',
      color: '#FFFFFF',
      borderRadius: '0',
      padding: '10px',
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: '#6f7b81',
      },

    },
  }));
  const classes = useStyles();

  const updateForDataComplements = () => {
    try {
      sessionStorage.setItem('@data-complements', 'true');
    } catch (error) {
      console.log(error);
    }
  };

  const handleRedirect = () => {
    localStorage.setItem('@path-complements-return', location.pathname);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle
        id="alert-dialog-title"
        className={classes.boxHeader}
      >
        <Typography
          className={classes.textName}
        >
          Olá! Identificamos que seus dados estão desatualizados.
          Gostaríamos de atualizá-los para garantir um atendimento adequado?
        </Typography>
      </DialogTitle>
      <DialogActions className={classes.actions}>
        <Button
          variant="contained"
          color="primary"
          className={classes.btnConfirm}
          href="/workspace/meus-dados"
          onClick={() => handleRedirect()}
        >
          Sim, preencher
        </Button>
        <Button
          onClick={() => {
            handleClose();
            updateForDataComplements();
          }}
          variant="contained"
          color="primary"
          className={classes.btnClose}
        >
          Não, depois faço isso
        </Button>
      </DialogActions>
    </Dialog>
  );
}
