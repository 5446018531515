import { AxiosResponse } from 'axios';
import { format } from 'date-fns';
import { apisiseci, apisso } from './api';

export const checarHorarios = (
  date: Date,
  unity: string,
  serviceID: number,
  tipo: string,
): Promise<AxiosResponse> => {
  const dateScheduler = format(date, 'yyyy-MM-dd');

  return apisiseci.get(
    `agendamento/checar_horarios/?data=${dateScheduler}&servico=${serviceID}&unidade=${unity}&tipo=${tipo}`,
  );
};

export const agendar = (
  token: string,
  cpf: string,
  servico: number,
  unidade: string,
  setor: string,
  tipo: string,
  date: Date,
  hora: string,
  tipo_documento?: string,
  documento?: string,
  titulo?: string,
  conteudo?: string,
  para_dependente?: boolean,
  dependentes?: {
    cpf: string,
    nome: string,
    data_nascimento: Date,
    cpf_solicitante: string,
    grau_parentesco: string,
  },
): Promise<AxiosResponse> => {
  const data = format(date, 'yyyy-MM-dd');
  return apisiseci.post(
    'agendamento/agendar/',
    {
      cpf,
      servico,
      unidade,
      setor,
      tipo,
      data,
      hora,
      titulo,
      tipo_documento,
      documento,
      conteudo,
      para_dependente,
      dependentes,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

export const getCheckInterval = (
  token: string,
  servico: number,
  unidade: string,
  data_inicio: string,
  data_fim: string,
  tipo: 'Online' | 'Presencial' | 'Espontâneo',
): Promise<AxiosResponse> => apisiseci.get<string[]>(
  `agendamento/checar_intervalo/?servico=${servico}&unidade=${unidade}&data_inicio=${data_inicio}&data_fim=${data_fim}&tipo=${tipo}`,
  {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  },
);

export const incluirDependente = (
  id_agendamento: number,
  cpf: string,
  nome: string,
  data_nascimento: Date,
  cpf_solicitante: string,
  grau_parentesco: string,
  token: string,
): Promise<AxiosResponse> => {
  // const dataInicial = new Date(data_nascimento);
  // const year = dataInicial.getFullYear();
  // const month = dataInicial.getMonth() + 1;
  // const day = dataInicial.getDay();
  // const data = `${year}-${month}-${day}`;
  const data = format(data_nascimento, 'yyyy-MM-dd');
  return apisiseci.post(
    'agendamento/dependente/',
    {
      agendamento: id_agendamento,
      cpf,
      nome,
      data_nascimento: data,
      cpf_solicitante,
      grau_parentesco,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

export interface IParamsHistory {
  status: string;
}

export const historico = (
  token: string,
  page: number,
  params?: IParamsHistory,
): Promise<AxiosResponse> => apisiseci.get(`agendamento/historico/?page=${page}`, {
  headers: {
    Authorization: `Bearer ${token}`,
  },
  params,
});

export const getHistorico = (
  token: string,
  id: number,
): Promise<AxiosResponse> => apisiseci.get(`agendamento/historico/${id}`, {
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export const cancelar = (
  token: string,
  id: string | number,
): Promise<AxiosResponse> => apisiseci.put(
  `agendamento/cancelar/${id}/`,
  {
    id,
    status: 'Cancelado',
  },
  {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  },
);

export const feriados = (): Promise<AxiosResponse> => apisiseci
  .get('cms/feriados');

export const comprovanteDiario = ({ ticket }: { ticket: string }): Promise<AxiosResponse> => apisiseci
  .get('/diario/atendimentos_ticket/', {
    params: {
      ticket,
    },
  });

export const comprovanteInterno = ({ ticket }: { ticket: string }): Promise<AxiosResponse> => apisiseci
  .get('/agendamento/agendamentos_internos_ticket/', {
    params: {
      ticket,
    },
  });

export const comprovanteAgenamento = ({ ticket }: { ticket: string }): Promise<AxiosResponse> => apisiseci
  .get('/agendamento/agendamentos_ticket/', {
    params: {
      ticket,
    },
  });

export const buscarCNPJ = (cnpj: string): Promise<AxiosResponse> => apisso
  .post('/admin/checar/cnpj/',
    {
      cnpj,
    });
export const getDependentes = async (token: string, cpf: string) => apisso.get('/cidadaos/dependente/', {
  headers: {
    Authorization: `Bearer ${token}`,
  },
});
