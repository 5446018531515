import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ParamsProps, UserDataProps } from './types';
import { getDataUserSso } from '../../../../services/auth';

export default function DemandMobileRedirect(): JSX.Element {
  const history = useHistory();
  const { slug, tokenSSO } = useParams<ParamsProps>();

  function handleRedirect(token: string, user: UserDataProps) {
    history.push({
      pathname: `/demanda/mobile/${slug}`,
      state: { token, user },
    });
  }

  async function fetchUsersData() {
    try {
      const { data: user } = await getDataUserSso(tokenSSO);

      if (user.length > 0 && tokenSSO) {
        document.cookie = `gov_access_token_sso=${tokenSSO}; path=/`;
        document.cookie = `gov_user_data=${encodeURIComponent(JSON.stringify(user[0]))}; path=/`;

        handleRedirect(
          tokenSSO,
          user[0],
        );
      }
    } catch (error) {
      history.replace('/');
      throw new Error('Algo deu errado', error);
    }
  }

  useEffect(() => {
    if (slug && tokenSSO) {
      fetchUsersData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug, tokenSSO]);

  return (
    <></>
  );
}
