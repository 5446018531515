import { AxiosResponse } from 'axios';
import { apisiseci } from './api';

export const getNotices = (): Promise<AxiosResponse> => apisiseci.get('cms/aviso/');

export const registrarDispositivo = (token, device_token): Promise<AxiosResponse> => apisiseci.post('profile/device/registrar',
  {
    device_token,
  },
  { headers: { Authorization: `Bearer ${token}` } });

export const removerDispositivo = (token, device_token): Promise<AxiosResponse> => apisiseci.post('profile/device/remover',
  {
    device_token,
  },
  { headers: { Authorization: `Bearer ${token}` } });
