import { Box, Dialog, DialogContent, DialogTitle, Divider, IconButton, makeStyles } from '@material-ui/core'
import React, { useContext } from 'react'
import colorContext from '../../Context/colorContext';
import { UnavailabilityI } from '../../Pages/SearchService';

interface Props {
  openUnavailabilityModal: boolean,
  handleClose: () => void,
  currentUnavailabilityData: UnavailabilityI,
}

export default function UnavailabilityModal({
  openUnavailabilityModal,
  handleClose,
  currentUnavailabilityData,
}: Props) {
  const { colors } = useContext(colorContext);

  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: colors.colorBackground,
      width: '700px',
      paddingBottom: '100px',
    },
    titleDialog: {
      color: colors.colorBackground,
      backgroundColor: colors.accentColorBackground,
      paddingTop: 20,
      paddingBottom: 20,
      paddingRight: 40,
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      bottom: theme.spacing(1),
      color: colors.accentColor,
      fontWeight: 700,
      fontSize: 18,
    },
    contentDialog: {
      color: colors.textBody,
      textAlign: 'justify',
      lineHeight: '19px',
    },
  }));
  const classes = useStyles();

  const convertDate = (date: string) => {
    if (!date) return
    const parsedDate = new Date(date);
    return `${parsedDate.toLocaleString('pt-BR', { dateStyle: 'short'})} às ${parsedDate.getHours()}:${parsedDate.getMinutes().toLocaleString('pt-BR', {minimumIntegerDigits: 2, useGrouping:false})}`
  }

  return (
    <Dialog
        open={openUnavailabilityModal}
        onClose={handleClose}
        aria-labelledby="block-dialog-title"
        maxWidth="lg"
      >
        <DialogTitle className={classes.titleDialog}>
            {currentUnavailabilityData?.titulo}
        </DialogTitle>
        <DialogContent dividers classes={{ root: classes.root }}>
            <Box
            className={classes.contentDialog}
            >
            <p><b>Período: </b> {convertDate(currentUnavailabilityData?.data_hora_inicio || '')} até {convertDate(currentUnavailabilityData?.data_hora_fim || '')}</p>
            </Box>
            <Divider />
            <Box
            className={classes.contentDialog}
            >
            <p><b>Motivo: </b> {currentUnavailabilityData?.motivo}</p>
            </Box>
        </DialogContent>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
            Fechar
        </IconButton>
    </Dialog>
  )
}


